import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-link-button.js';
import '@banno/platform-ux-shared/components/polymer3/jha/cards/jha-card-platform.js';
import '@banno/platform-ux-shared/components/polymer3/jha/flex-wrapper/jha-flex-wrapper.js';
import '@banno/platform-ux-shared/components/polymer3/jha/grid/jha-grid.js';
import '@banno/platform-ux-shared/components/polymer3/jha/thumbnail/jha-thumbnail.js';
import BrowserCheckService from '@banno/platform-ux-shared/services/browser-check/browser-check-service.js';
import {routingMixin as RouterMixin} from '@jack-henry/web-component-router';
const baseLogoURL = '../img/platform/logos/';
const browserVenderLinks = {
  Chrome: 'https://www.google.com/chrome',
  Firefox: 'https://www.firefox.com/',
  IE: 'http://windows.microsoft.com/ie',
  Safari: 'https://www.apple.com/safari/',
  'Mobile Safari': 'https://www.apple.com/safari/',
  Edge: 'https://www.microsoft.com/en-us/edge'
};
const browserVendorLogos = {
  Chrome: baseLogoURL + 'chrome-logo.png',
  Firefox: baseLogoURL + 'firefox-logo.png',
  IE: baseLogoURL + 'ie-logo.png',
  Safari: baseLogoURL + 'safari-logo.png',
  'Mobile Safari': baseLogoURL + 'safari-logo.png',
  Edge: baseLogoURL + 'edge-logo.png'
};
/**
 * @constructor
 * @polymer
 * @extends {PolymerElement}
 */
const BrowserUpgradeBase = RouterMixin(PolymerElement);
/** @polymer */
class BrowserUpgradeElement extends BrowserUpgradeBase {
  static get is() {
    return 'browser-upgrade';
  }
  static get properties() {
    return {
      browserCheck: {
        type: Object,
        value: BrowserCheckService.getInfo()
      }
    };
  }

  _computeBrowserLink() {
    return browserVenderLinks[this.browserCheck.clientBrowser.name];
  }

  _computeBrowserLogo() {
    return browserVendorLogos[this.browserCheck.clientBrowser.name];
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
      }
      jha-card-platform {
        width: 100%;
        margin-bottom: 0;
      }
      jha-card-platform > header,
      footer {
        text-align: center;
      }
      jha-card-platform > header {
        border-bottom: none;
      }
      h1 {
        font-size: 20px;
        font-weight: 400;
        margin-top: 24px;
        margin-bottom: 12px;
      }
      jha-grid {
        padding-bottom: 0;
      }
      .grid-6-xs {
        box-sizing: border-box;
      }
      .grid-6-xs header {
        color: var(--jha-text-light);
        font-weight: 600;
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 16px;
      }
      p {
        margin-bottom: 0;
      }
      jha-flex-wrapper {
        align-items: flex-start;
        justify-content: flex-start;
      }
      jha-flex-wrapper > div {
        font-size: 13px;
      }
      jha-flex-wrapper > div div:first-of-type {
        font-size: 16px;
        font-weight: 600;
        color: var(--jha-color-dark);
      }
      jha-flex-wrapper > div div.danger {
        color: var(--jha-color-danger);
      }
      jha-flex-wrapper jha-link-button {
        --jha-button-text: var(--jha-text-light);
        --jha-button-text-size: 13px;
        --jha-button-padding-horizontal: 0;
        text-transform: uppercase;
        font-weight: 600;
      }
      jha-flex-wrapper jha-link-button:hover {
        --jha-button-text: var(--jha-color-primary);
      }
      footer {
        padding-top: 0;
        padding-bottom: 32px;
      }
      footer jha-link-button {
        --jha-button-text: var(--jha-color-primary);
      }
      @media (min-width: 480px) {
        jha-card-platform {
          width: 90%;
          max-width: 550px;
        }
      }
      @media (max-width: 480px) {
        jha-card-platform {
          min-height: 100vh;
          max-height: 100%;
        }
      }
    </style>
    <jha-card-platform>
      <header>
        <h1>We can't let you in.</h1>
      </header>
      <article>
        <p>Your browser is too old to support the minimum security measures we require to keep your data safe. Update your browser to the latest version to login.</p>
        <jha-grid>
          <div class="grid-6-xs">
            <header>Your browser</header>
            <jha-flex-wrapper>
              <jha-thumbnail small="">
                <img src$="[[_computeBrowserLogo()]]" alt="[[browserCheck.browser.name]]">
              </jha-thumbnail>
              <div>
                <div>[[browserCheck.clientBrowser.name]]</div>
                <div class="danger">version [[browserCheck.clientBrowser.major]]</div>
              </div>
            </jha-flex-wrapper>
          </div>
          <div class="grid-6-xs">
            <header>Minimum supported</header>
            <jha-flex-wrapper>
              <jha-thumbnail small="">
                <img src$="[[_computeBrowserLogo()]]" alt="[[browserCheck.supportedBrowser.name]]">
              </jha-thumbnail>
              <div>
                <div>[[browserCheck.clientBrowser.name]]</div>
                <div>version [[browserCheck.supportedBrowser.version]]</div>
                <jha-link-button icon="" href$="[[_computeBrowserLink()]]">Download</jha-link-button>
              </div>
            </jha-flex-wrapper>
          </div>
        </jha-grid>
        <p>You can use the latest version of any major browser like Microsoft Edge, Chrome, Firefox, or Safari.</p>
      </article>
      <footer>
        <jha-link-button icon="" href="/a/browser/index.html">View our browser support policy</jha-link-button>
      </footer>
    </jha-card-platform>
  `;
  }
}
customElements.define(BrowserUpgradeElement.is, BrowserUpgradeElement);
export default BrowserUpgradeElement;
